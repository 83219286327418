export type GPSRInformationProps = {
  gpsrInfo: Core.Product['gpsrInfo']
} & TranslateProps

export function GPSRInformation({ gpsrInfo, t }: Readonly<GPSRInformationProps>) {
  return (
    <section className="gpsr-information">
      <ProductSafetyInformation
        safetyStatements={gpsrInfo.safetyStatements}
        supplemetaryInformation={gpsrInfo.supplemetaryInformation}
        pictograms={gpsrInfo.pictograms}
        t={t}
      />
      <ManufacturerInformation
        manufacturerAddress={gpsrInfo.manufacturerAddress}
        responsiblePersonAddress={gpsrInfo.responsiblePersonAddress}
        t={t}
      />
    </section>
  )
}

export type ProductSafetyInformationProps = {
  safetyStatements: Core.Product['gpsrInfo']['safetyStatements']
  supplemetaryInformation: Core.Product['gpsrInfo']['supplemetaryInformation']
  pictograms: Core.Product['gpsrInfo']['pictograms']
} & TranslateProps

function ProductSafetyInformation({
  safetyStatements,
  supplemetaryInformation,
  pictograms,
  t,
}: Readonly<ProductSafetyInformationProps>) {
  if (safetyStatements.length === 0 && pictograms.length === 0 && !supplemetaryInformation) return null

  return (
    <details>
      <summary>{t('components.productComponent.gpsrInformation.productSafetyInformationTitle')}</summary>
      {safetyStatements.length > 0 ? (
        <ul>
          {safetyStatements.map((safetyStatement) => (
            <li key={safetyStatement}>{safetyStatement}</li>
          ))}
        </ul>
      ) : null}
      {supplemetaryInformation ? <p>{supplemetaryInformation}</p> : null}
      {pictograms.length > 0 ? (
        <div className="pictogram">
          {pictograms.map((pictogram) => (
            <img key={pictogram} src={pictogram} alt="" />
          ))}
        </div>
      ) : null}
    </details>
  )
}

type ManufacturerInformationProps = {
  manufacturerAddress: Core.Product['gpsrInfo']['manufacturerAddress']
  responsiblePersonAddress: Core.Product['gpsrInfo']['responsiblePersonAddress']
} & TranslateProps

function ManufacturerInformation({
  manufacturerAddress,
  responsiblePersonAddress,
  t,
}: Readonly<ManufacturerInformationProps>) {
  if (!manufacturerAddress && !responsiblePersonAddress) return null

  return (
    <details>
      <summary>{t('components.productComponent.gpsrInformation.manufacturerInformationTitle')}</summary>
      <div className="manufacturer">
        {manufacturerAddress ? (
          <address>
            <span>{t('components.productComponent.gpsrInformation.manufacturerAddressHeadline')}</span>
            <span>{manufacturerAddress.company}</span>
            <span>{manufacturerAddress.street}</span>
            {manufacturerAddress.streetDetails && <span>{manufacturerAddress.streetDetails}</span>}
            <span>
              {manufacturerAddress.zipCode} {manufacturerAddress.city}
            </span>
            {manufacturerAddress.state && <span>{manufacturerAddress.state}</span>}
            <span>{manufacturerAddress.country}</span>
            <br></br>
            {manufacturerAddress.phoneNumber && <span>{manufacturerAddress.phoneNumber}</span>}
            {isURL(manufacturerAddress.emailOrContactFormUrl) ? (
              <a href={manufacturerAddress.emailOrContactFormUrl}>{manufacturerAddress.emailOrContactFormUrl}</a>
            ) : (
              <span>{manufacturerAddress.emailOrContactFormUrl}</span>
            )}
          </address>
        ) : null}
        {responsiblePersonAddress ? (
          <address>
            <span>{t('components.productComponent.gpsrInformation.responsiblePersonAddressHeadline')}</span>
            <span>{responsiblePersonAddress.company}</span>
            <span>{responsiblePersonAddress.street}</span>
            {responsiblePersonAddress.streetDetails && <span>{responsiblePersonAddress.streetDetails}</span>}
            <span>
              {responsiblePersonAddress.zipCode} {responsiblePersonAddress.city}
            </span>
            {responsiblePersonAddress.state && <span>{responsiblePersonAddress.state}</span>}
            <span>{responsiblePersonAddress.country}</span>
            <br></br>
            {responsiblePersonAddress.phoneNumber && <span>{responsiblePersonAddress.phoneNumber}</span>}
            <span>{responsiblePersonAddress.emailAddress}</span>
          </address>
        ) : null}
      </div>
    </details>
  )
}

function isURL(input: string): boolean {
  let url: URL

  try {
    url = new URL(input)
  } catch {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
